/** @jsx jsx */
import * as React from "react"
import { jsx, Box } from "theme-ui"
import { Link } from "gatsby"
import ItemTags from "@lekoarts/gatsby-theme-minimal-blog/src/components/item-tags"

type BlogListItemProps = {
  post: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }
  showTags?: boolean
}

const BlogListItem = ({ post, showTags = true }: BlogListItemProps) => {
  const date = new Date(post.date)
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'America/New_York',
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  }
  const formattedDate = date.toLocaleString("en-US", options)

  return (
    <Box mb={4}>
      <Link to={post.slug} sx={(t) => ({ ...t.styles?.a, fontSize: [1, 2], color: `text` })}>
        {post.title}
      </Link>
      <p sx={{ color: `secondary`, mt: 1, a: { color: `secondary` }, fontSize: [1] }}>
        <time title={date.toISOString()} dateTime={date.toISOString()}>{formattedDate}</time>
        {post.tags && showTags && (
          <React.Fragment>
            {` — `}
            <ItemTags tags={post.tags} />
          </React.Fragment>
        )}
      </p>
    </Box>
  )
}

export default BlogListItem
